import { Component } from "react";
import qu4x from "../../assets/img/QU4X-LogoImageTextMouth.png";
import { BsMenuButtonWideFill } from "react-icons/bs";
import { BiWindowClose } from "react-icons/bi";
import { MenuData } from "./MenuData";
import "./NavbarStyles.css";

class Navbar extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <nav className="NavbarItems">
        <div className="NavLogo">
          <img src={qu4x} className="QU4X" alt="QU4X" />
        </div>
        <div className="menu-icons" onClick={this.handleClick}>
          <BiWindowClose
            className={
              this.state.clicked ? "hamburger-open" : "hamburger-closed"
            }
          />
          <BsMenuButtonWideFill
            className={
              this.state.clicked ? "hamburger-closed" : "hamburger-open"
            }
          />
        </div>
        <ul className={this.state.clicked ? "NavMenu active" : "NavMenu"}>
          {MenuData.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.url} className={item.cName}>
                  {item.icon}
                  {item.title}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default Navbar;
