import logo from "../../assets/img/MouthRainbow.png";
import duck from "../../assets/img/duck.png";
import hunter from "../../assets/img/hunter.png";
import goose from "../../assets/img/goose.png";
import qu4x17 from "../../assets/img/17.png";
import qu4x120 from "../../assets/img/120.png";
import qu4x4 from "../../assets/img/4.png";
import qu4x145 from "../../assets/img/145.png";
import qu4x121 from "../../assets/img/121.png";
import qu4x170 from "../../assets/img/170.png";
import qu4x326 from "../../assets/img/326.png";
import qu4x229 from "../../assets/img/229.png";
import qu4x48 from "../../assets/img/48.png";
import qu4x264 from "../../assets/img/264.png";
import qu4x224 from "../../assets/img/224.png";
import qu4x187 from "../../assets/img/187.png";
import qu4x341 from "../../assets/img/341.png";
import rocketsauce from "../../assets/img/PBRS_Banner_TwitterWide.png";
import { Component } from "react";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  StickyOut,
  ZoomIn,
  ZoomOut,
} from "react-scroll-motion";
import "./HomeStyles.css";

const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
const FadeUp = batch(Fade(), MoveIn(-500, 0), Sticky());

class Home extends Component {
  render() {
    return (
      <ScrollContainer>
        <ScrollPage page={0}>
          <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
            <section>
              <img src={logo} className="App-logo" alt="logo" />
              <h1 className="QTitle">QU4X</h1>
              <p className="ScrollAlert">(Scroll Down)</p>
            </section>
          </Animator>
        </ScrollPage>

        <ScrollPage page={1}>
          <Animator animation={ZoomInScrollOut}>
            <h1>
              <span className="accent">
                Welcome to the pond, motherflockers
              </span>
            </h1>
            <p>QUACK! QUACK!</p>
          </Animator>
          <Animator animation={batch(MoveIn(1920, 0), Sticky(), ZoomOut())}>
            <img src={qu4x4} className="sneak-peek" alt="DuckSneakPeek" />
          </Animator>
        </ScrollPage>

        <ScrollPage page={2}>
          <Animator animation={batch(MoveIn(1920, 0), Sticky(), ZoomOut())}>
            <div className="duck-preview-2">
              <img
                src={qu4x17}
                className="sneak-peek grid-item-a"
                alt="DuckSneakPeek"
              />
              <img
                src={qu4x341}
                className="sneak-peek grid-item-b"
                alt="DuckSneakPeek"
              />
              <img
                src={qu4x121}
                className="sneak-peek grid-item-c"
                alt="DuckSneakPeek"
              />
              <img
                src={qu4x224}
                className="sneak-peek grid-item-d"
                alt="DuckSneakPeek"
              />
              <img
                src={qu4x145}
                className="sneak-peek grid-item-e"
                alt="DuckSneakPeek"
              />
              <img
                src={qu4x187}
                className="sneak-peek grid-item-f"
                alt="DuckSneakPeek"
              />
            </div>
          </Animator>
        </ScrollPage>

        <ScrollPage page={3}>
          <Animator animation={ZoomInScrollOut}>
            <div className="duck-preview-1">
              <img
                src={qu4x120}
                className="sneak-peek grid-duck"
                alt="DuckSneakPeek"
              />
              <img
                src={qu4x326}
                className="sneak-peek grid-duck"
                alt="DuckSneakPeek"
              />
              <img
                src={qu4x264}
                className="sneak-peek grid-duck"
                alt="DuckSneakPeek"
              />
              <img
                src={qu4x48}
                className="sneak-peek grid-duck"
                alt="DuckSneakPeek"
              />
              <img
                src={qu4x229}
                className="sneak-peek grid-duck"
                alt="DuckSneakPeek"
              />
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage page={4}>
          <Animator animation={FadeUp}>
            <section>
              <h2>
                MINT begins on November 5th, 2022 and is{" "}
                <span className="accent">FREE</span> and available for OG
                holders ONLY
              </h2>
              <p>
                Public Sale of remaining supply will follow at{" "}
                <span className="accent">1.5 SOL</span> each <br></br>
                For every Original Dapper Ducks NFT you hold in your wallet at
                the time of snapshot, you'll receive:
              </p>
              <h2>
                {" "}
                <span className="accent">1 QU4X</span> for every Dapper Duck{" "}
                <img src={duck} className="nft-icon" alt="Duck" />
              </h2>
              <h2>
                {" "}
                <span className="accent">2 QU4X</span> for every Hunter{" "}
                <img src={hunter} className="nft-icon" alt="Hunter" />
              </h2>
              <h2>
                {" "}
                <span className="accent">4 QU4X</span> for every Goose{" "}
                <img src={goose} className="nft-icon" alt="Goose Gang" />
              </h2>
            </section>
          </Animator>
        </ScrollPage>

        <ScrollPage page={5}>
          <Animator animation={FadeUp}>
            <section>
              <h2>
                SNAPSHOT will be this week, so DELIST NOW!{" "}
                <span className="accent">ALSO</span> you can now convert your
                EGGZ to FREE MINTS!
              </h2>
              <p>
                Follow this link to convert 10,000 EGGZ to FREE MINT tokens:{" "}
                <span className="accent">
                  <a href="https://app.strataprotocol.com/swap/8U9zyD7VALG9f8JzsQda5WPThF6LNYSZ8vvfPkhXDvnT">
                    CLICK HERE FOR EGGZ SWAP
                  </a>
                </span>{" "}
                <br></br>
              </p>
              <h2>
                This limited edition OG mint will include a special OG TATTOO
                trait that no future mint of this collection will have. It will
                separate YOU from the rest of QU4X holders and will likely
                include special perks down the line at the official public mint!
              </h2>
            </section>
          </Animator>
        </ScrollPage>

        <ScrollPage page={6}>
          <Animator animation={batch(Fade(), StickyOut())}>
            <section>
              <h2>
                Join The{" "}
                <span className="accent">
                  <a href="https://discord.gg/f2xRXDqn2s">Discord</a>
                </span>{" "}
                or Follow Us On{" "}
                <span className="accent">
                  <a href="https://twitter.com/QU4Xnft">Twitter</a>
                </span>{" "}
                for more information!
              </h2>
              <p>(Let's Flocking GO!)</p>
              <img src={qu4x170} className="sneak-peek" alt="DuckSneakPeek" />
            </section>
          </Animator>
        </ScrollPage>

        <ScrollPage page={7}>
          <Animator animation={batch(Fade(), StickyOut())}>
            <section>
              <></>
            </section>
          </Animator>
        </ScrollPage>

        <ScrollPage page={8}>
          <Animator animation={ZoomInScrollOut}>
            <section>
              <img
                src={rocketsauce}
                className="rocketsauce"
                alt="Rocketsauce Logo"
              />
              <div className="footer">
                ©2022 {"   "}
                <a href="https://rocketsauce.xyz" className="social-links">
                  RocketMedia
                </a>
                {"   "} - {"   "}
                <a href="https://twitter.com/gh0xsted" className="social-links">
                  @GH0xSTED
                </a>
              </div>
            </section>
          </Animator>
        </ScrollPage>
      </ScrollContainer>
    );
  }
}

export default Home;
