import { FaHome, FaDiscord, FaTwitter, FaExchangeAlt } from "react-icons/fa";
import { TiInfo } from "react-icons/ti";
export const MenuData = [
  {
    title: " HOME",
    url: "/",
    cName: "nav-links",
    icon: <FaHome />,
  },
  {
    title: " EGGZ SWAP",
    url: "https://app.strataprotocol.com/swap/8U9zyD7VALG9f8JzsQda5WPThF6LNYSZ8vvfPkhXDvnT",
    cName: "nav-links",
    icon: <FaExchangeAlt />,
  },
  {
    title: " DISCORD",
    url: "https://discord.gg/f2xRXDqn2s",
    cName: "nav-links",
    icon: <FaDiscord />,
  },
  {
    title: " TWITTER",
    url: "https://twitter.com/QU4Xnft",
    cName: "nav-links",
    icon: <FaTwitter />,
  },
  {
    title: " ABOUT",
    url: "/",
    cName: "nav-links",
    icon: <TiInfo />,
  },
  {
    title: "CONNECT",
    url: "/",
    cName: "nav-links-connect",
  },
];
